import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import React, { useState, useEffect } from 'react';
import "./styles.css";
import * as other from "./other.js"

function App(){
  other.LoadDefaults() //This is for loading default settings, not yet implemented
  const [isGuessName, setIsGuessName] = useState(false)
  const [isGuessID, setIsGuessID] = useState(false)
  const [isGuessFullGens, setIsGuessFullGens] = useState(false)
  const playingVars = [isGuessName, isGuessID, isGuessFullGens]
  const playingFuncs = [() => setIsGuessName(!isGuessName),() => setIsGuessID(!isGuessID),() => setIsGuessFullGens(!isGuessFullGens)]

  return (
    <Main vars={playingVars} funcs={playingFuncs}/>
  )
}

function Main({vars, funcs}){
  if (vars[0]){ //Playing Guess Name
    return (
      <GuessName back={funcs[0]}/>
    )
  }

  else if (vars[1]){ //Playing Guess ID
    return (
      <GuessID back={funcs[1]}/>
    )
  }

  else if (vars[2]){ //Playing Guess Full Gens
    return (
      <GuessFullGens back={funcs[2]}/>
    )
  }

  else {
    return (
      <div className="col-flex-container">
        <Header/>
        <Button text="Guess Pokemon Names" onClick = {funcs[0]}/> {/*Original Game (Guess name based on whatever) GuessName*/}
        <Button text="Guess Pokedex Number" onClick = {funcs[1]}/> {/*Guess ID based on whatever GuessID*/}
        <Button text="Guess All Pokemon in Selected Gens" onClick = {funcs[2]}/> {/*List game mode w/ input boxes, guess all pokemon for selected gens GuessFullGens*/}
      </div>
    )
  }
}

function GuessName({back}){
  other.options["name"] = false
  other.options["id"] = true
  const [isPlaying, setIsPlaying] = useState(false)
  const togglePlaying = () => setIsPlaying(!isPlaying)

  return isPlaying ? <GuessGame onQuit={togglePlaying} guessName={true} guessID={false}/> : (
    <div className="col-flex-container">
      <h2>Guess Name</h2>
      <SelectionCard name={false} ID={true} FullGens={false}/>
      <Button text="Start" onClick = {togglePlaying}/>
      <Button text="Back" onClick = {back}/>
    </div>
  )
}

function GuessGame({onQuit, guessName, guessID}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [gaveUp, setGaveUp] = useState(false); //allows for use to see pokemon
  const [correct, setCorrect] = useState(false); //for pokemoninput and to check if can give up

  const [reset, setReset] = useState(false)
  const newGame = () => setReset(!reset)
  const giveup = () => {if (!correct){setGaveUp(true)}}

  useEffect(() => {
    async function fetchData() {
      try {
        console.log('Fetching data...');
        const result = await other.getRandomPokemonData();
        console.log('Data fetched:', result);
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Pokémon data:', error);
        setLoading(false);
      }
    }

    fetchData();
    setGaveUp(false)
  }, [reset]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="col-flex-container">
      <HintList data={data} />
      <div className="col-flex-container">
        {gaveUp ? (<p>{guessName ? other.currentPokemon : other.currentPokemonID}</p>) : (<></>)} {/*might change pokemon name displayed later*/}
        <PokemonInput reset={reset} newGame={newGame} gaveUp={gaveUp} correct={correct} setCorrect={setCorrect} guessName={guessName} guessID={guessID}/>
        <Button text="Quit" onClick={onQuit}/>
        <Button text="Next" onClick={newGame}/>
        <Button text="Give up" onClick={giveup}/>
      </div>
    </div>
  );
}

function GuessID({back}){
  other.options["name"] = true
  other.options["id"] = false
  const [isPlaying, setIsPlaying] = useState(false)
  const togglePlaying = () => setIsPlaying(!isPlaying)

  return isPlaying ? <GuessGame onQuit={togglePlaying} guessName={false} guessID={true}/> : (
    <div className="col-flex-container">
      <h2>Guess ID</h2>
      <SelectionCard name={true} ID={false} FullGens={false}/>
      <Button text="Start" onClick = {togglePlaying}/>
      <Button text="Back" onClick = {back}/>
    </div>
  )
}

function GuessFullGens({back}){
  const [isPlaying, setIsPlaying] = useState(false)
  const togglePlaying = () => setIsPlaying(!isPlaying)

  return isPlaying ? <GuessFullGensGame onQuit={togglePlaying}/> : (
    <div className="col-flex-container">
      <h2>Guess Full Gens</h2>
      <SelectionCard name={false} ID={false} FullGens={true}/>
      <Button text="Start" onClick = {togglePlaying}/>
      <Button text="Back" onClick = {back}/>
    </div>
  )
}

function GuessFullGensGame({onQuit}) {
  //Idea for now
  //For each gen, have a card with a text box for each pokemon in the gen. These should stretch horizontally and wrap. Maybe even line them up
  //Stack gens on top of each other
  const [data, setData] = useState(null); //Used to retrieve data
  const [loading, setLoading] = useState(true); //Tells function whether we are still gathering data or not

  const [reset, setReset] = useState(false)

  useEffect(() => {
    async function fetchData() {
      try {
        console.log('Fetching data...');
        const result = await other.get_batch_pokemon_data();
        console.log('Data fetched:', result);
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Pokémon data:', error);
        setLoading(false);
      }
    }

    fetchData();
  }, [reset]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="col-flex-container">
      <FullGensInputList allGensData={data} />
      <div className="col-flex-container">
        <Button text="Quit" onClick={onQuit}/>
      </div>
    </div>
  );
}

function Header(){
  return(
      <h1>
        Super Duper Pokemon Guessing Game
      </h1>
  )
}

function SelectionCard({name, ID, FullGens}){
  if(FullGens){
    return (
      <div className = "col-flex-container">
        <div className = "card">
          <GensCheckBoxList/>
        </div>
      </div>
    )
  }
  
  return (
    <div className = "row-flex-container">
      <div className = "card">
        <HintsCheckBoxList name={name} ID={ID}/>
      </div>
      <div className = "card">
        <GensCheckBoxList/>
      </div>
    </div>
  )
}

function HintsCheckBoxList({name, ID}){
  return (
      <div className = "options-list">
        <h3>Select Hints you would like to be given</h3>
        {Object.keys(other.options).map((option) => (
          <CheckBox key={option} value={option} dict={other.options} on={other.options[option]} useName={name} useID={ID}/>
        ))}
      </div>
  )
}

function GensCheckBoxList(){
  return (
      <div className = "options-list">
        <h3>Select which generations to include</h3>
        {Object.keys(other.gens).map((option) => (
          <CheckBox key={option} value={option} dict={other.gens} on={other.gens[option]}/>
        ))}
      </div>
  )
}

function CheckBox({value, dict, on, useName, useID}){
  const [isChecked, setIsChecked] = useState(on)
  const handleChange = () => {setIsChecked(!isChecked)}
  dict[value] = isChecked

  if(!(value === "name" && !useName)){ // only include name if useName and only include ID if useID
    if(!(value === "id" && !useID)){
      return (
        <div className = "option">
          <label>
            <input type = "checkbox"
              checked = {isChecked}
              onChange = {handleChange}
            />
            {" " + value}
          </label>
        </div>
      )
    }
  }
}

function Button({text, onClick}){
  return(
    <div>
      <button className = "button" role="button" onClick={onClick}>
        {text}
      </button>
    </div>
  )
}

function PokemonInput({reset, newGame, gaveUp, correct, setCorrect, guessName, guessID}){
  const [guess, setGuess] = useState("")
  useEffect(() => {setCorrect(false)}, [reset]);

  const keyStrokeFunction = (e) => {
    if (e.key === "Enter" && !gaveUp){
      if(correct){
        setGuess("")
        newGame()
      }
      else if ((guess.toLowerCase().trim() == other.currentPokemon || (other.correctAnswers[other.currentPokemon] != null && other.correctAnswers[other.currentPokemon].indexOf(guess.toLowerCase().trim()) != -1)) && guessName){
        setCorrect(true)
      }
      else if ((guess.trim() === other.currentPokemonID) && guessID){
        setCorrect(true)
      }
      else{
        setCorrect(false)
      }
    }
  }

  return (
    <div>
      <label>
        Enter your guess here: <input name="Guess" value={guess} onChange={e => setGuess(e.target.value)} onKeyDown={keyStrokeFunction}/>
      </label>
      <p>{correct ? "Correct!" : ""}</p>
    </div>
  )
}

function FullGensInputList({allGensData}){
  return(
    <div className="col-flex-container">
      {allGensData.map((singleGenData) => <GenInputList key={singleGenData[0]} genNumber={singleGenData[0]} genData={singleGenData[1]}/>)}
    </div>
  )
}

function GenInputList({genNumber, genData}){
  return(
    <div className="col-flex-container">
      <h2>Generation {genNumber}</h2>
      <div className="row-flex-container2">
        {genData.map((singlePokeData) => <GuessGenPokemonInput key={singlePokeData[0]} pokeID={singlePokeData[0]} pokeName={singlePokeData[1]}/>)}
      </div>
    </div>
  )
}

function GuessGenPokemonInput({pokeName, pokeID}){
  const [guess, setGuess] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const [wasCorrect, setWasCorrect] = useState(true)
  //useEffect(() => {setCorrect(false)}, [reset]);

  const onChangeFunction = (e) => {
    if(!isChecked){
      setGuess(e.target.value)
      if(isUpdated){
        setIsChecked(true)
      }
    }
  }

  const keyStrokeFunction = (e) => {
    if((pokeName.toLowerCase() === (guess+e.key).trim().toLowerCase()) || (other.correctAnswers[pokeName] != null && other.correctAnswers[pokeName].indexOf((guess+e.key).toLowerCase().trim()) != -1)){
      setIsUpdated(true)
    }
  }

  let ID = parseInt(pokeID)
  if(parseInt(ID) < 10){
    return(
      <div>
        <label className="stretch-label">
        <p>&nbsp;&nbsp;&nbsp;{pokeID}:</p> <input className="input-box" name="Guess" value={guess} onChange={onChangeFunction} onKeyDown={keyStrokeFunction}/> <input type="checkbox" onChange={() => {setGuess(pokeName);setIsChecked(true)}} checked={isChecked}/>
        </label>
      </div>
    )
  }
  if(parseInt(ID) < 100){
    return(
      <div>
        <label className="stretch-label">
        <p>&nbsp;&nbsp;{pokeID}:</p> <input className="input-box" name="Guess" value={guess} onChange={onChangeFunction} onKeyDown={keyStrokeFunction}/> <input type="checkbox" onChange={() => {setGuess(pokeName);setIsChecked(true)}} checked={isChecked}/>
        </label>
      </div>
    )
  }
  if(parseInt(ID) < 1000){
    return(
      <div>
        <label className="stretch-label">
          <p>&nbsp;{pokeID}:</p> <input className="input-box" name="Guess" value={guess} onChange={onChangeFunction} onKeyDown={keyStrokeFunction}/> <input type="checkbox" onChange={() => {setGuess(pokeName);setIsChecked(true)}} checked={isChecked}/>
        </label>
      </div>
    )
  }
  else{
    return(
      <div>
        <label className="stretch-label">
        <p>{pokeID}:</p> <input className="input-box" name="Guess" value={guess} onChange={onChangeFunction} onKeyDown={keyStrokeFunction}/> <input type="checkbox" onChange={() => {setGuess(pokeName);setIsChecked(true)}} checked={isChecked}/>
        </label>
      </div>
    )
  }
}

function HintList({data}){
  return(
    <div>
      {Object.keys(data).map((hint) => (
        <Hint key={hint} hint={hint} value={data[hint]}/>
      ))}
    </div>
  )
}

function Hint({hint, value}){
  const myData = other.ParseHint(hint, value)
  return(
    <div>
      <p>{hint}: {myData.toString()}</p>
    </div>
  )
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);