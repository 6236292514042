export const api_url = "https://pokeapi.co/api/v2/pokemon/";
export let currentPokemon = "None"
export let currentPokemonID = "-1"

export const correctAnswers = {
    "nidoran-f" : ["nidoran-f", "nidoran-female", "nidoran f", "nidoran female"],
    "nidoran-m" : ["nidoran-m", "nidoran-male", "nidoran m", "nidoran male"],
    "farfetchd" : ["farfetchd", "farfetch\'d"],
    "porygon2" : ["porygon2", "porygon 2", "porygon-2"],
    "ho-oh" : ["ho-oh", "ho oh"],
    "mime-jr" : ["mime-jr", "mime-jr.", "mime jr", "mime jr."],
    "porygon-z" : ["porygonz", "porygon-z", "porygon z"],
    "giratina-altered" : ["giratina", "giratina-altered", "giratina altered"],
    "shaymin-land" : ["shaymin", "shaymin-land", "shaymin land"],
    "arceus-normal" : ["arceus", "arceus-normal", "arceus normal"],
    "wormadam-plant" : ["wormadam"],
    "sawsbuck-spring" : ["sawsbuck"],
    "deerling-spring" : ["deerling"],
    "tornadus-incarnate" : ["tornadus", "tornadus-incarnate", "tornadus incarnate", "tornadus-therian", "tornadus incarnate"],
    "thundurus-incarnate" : ["thundurus", "thundurus-incarnate", "thundurus incarnate", "thundurus-therian", "thundurus incarnate"],
    "landorus-incarnate" : ["landorus", "landorus-incarnate", "landorus incarnate", "landorus-therian", "landorus incarnate"],
    "keldeo-ordinary" : ["keldeo", "keldeo-ordinary", "keldeo ordinary"],
    "meloetta-aria" : ["meloetta", "meloetta-aria", "meloetta aria"],
    "vivillon-meadow" : ["vivillon", "vivillon-meadow", "vivillon meadow"],
    "flabebe-red" : ["flabebe", "flabebe-red", "flabebe red"],
    "floette-red" : ["floette", "floette-red", "floette red"],
    "florges-red" : ["florges", "florges-red", "florges red"],
    "furfrou-natural" : ["furfrou", "furfrou-natural", "furfrou natural"],
    "meowstic-male" : ["meowstic", "meowstic-male", "meowstic male", "meowstic-female", "meowstic female"],
    "aegislash-shield" : ["aegislash", "aegislash-shield", "aegislash shield", "aegislash-sword", "aegislash sword"],
    "pumpkaboo-average" : ["pumpkaboo", "pumpkaboo-average", "pumpkaboo average"],
    "gourgeist-average" : ["gourgeist", "gourgeist-average", "gourgeist average"],
    "xerneas-active" : ["xerneas", "xerneas-active", "xerneas active", "xerneas-neutral", "xerneas neutral"],
    "zygarde-50" : ["zygarde", "zygarde-50", "zygarde 50"],
    "oricorio-baile" : ["oricorio", "oricorio-baile", "oricorio baile"],
    "lycanroc-midday" : ["lycanroc", "lycanroc-midday", "lycanroc midday"],
    "wishiwashi-solo" : ["wishiwashi", "wishiwashi-solo", "wishiwashi solo"],
    "type-null" : ["type:null", "type-null", "type null"],
    "minior-red-meteor" : ["minior", "minior-red-meteor", "minior red meteor"],
    "mimikyu-disguised" : ["mimikyu", "mimikyu-disguised", "mimikyu disguised"],
    "jangmo-o" : ["jangmo-o", "jangmo o"],
    "hakamo-o" : ["hakamo-o", "hakamo o"],
    "kommo-o" : ["kommo-o", "kommo o"],
    "tapu-koko" : ["tapu koko", "tapu-koko"],
    "tapu-lele" : ["tapu lele", "tapu-lele"],
    "tapu-bulu" : ["tapu bulu", "tapu-bulu"],
    "tapu-fini" : ["tapu fini", "tapu-fini"],
    "toxtricity-amped" : ["toxtricity", "toxtricity-amped", "toxtricity amped"],
    "sinistea-phony" : ["sinistea", "sinistea-phony", "sinistea phony"],
    "polteageist-phony" : ["polteageist", "polteageist-phony", "polteageist phony"],
    "sirfetchd" : ["sirfetch\'d", "sirfetchd"],
    "mr-rime" : ["mr-rime", "mr.rime", "mr rime", "mr. rime"],
    "alcremie-vanilla-cream" : ["alcremie", "alcremie-vanilla-cream", "alcremie vanilla cream"],
    "eiscue-ice" : ["eiscue", "eiscue ice", "eiscue ice"],
    "indeedee-male" : ["indeedee", "indeedee male", "indeedee-male", "indeedee female", "indeedee-female"],
    "morpeko-full-belly" : ["morpeko", "morpeko-full-belly", "morpeko full belly"],
    "urshifu-single-strike" : ["urshifu", "urshifu single strike", "urshifu-single-strike"],
    "basculegion-male" : ["basculegion", "basculegion male", "basculegion-male", "basculegion female", "basculegion-female"],
    "enamorus-incarnate" : ["enamorus", "enamorus-incarnate", "enamorus incarnate", "enamorus-therian", "enamorus incarnate"],
    "maushold-family-of-four" : ["maushold", "maushold-family-of-four", "maushold family of four"],
    "squawkabilly-green-plumage" : ["squawkabilly", "squawkabilly-green-plumage", "squawkabilly green plumage"],
    "palafin-zero" : ["palafin", "palafin-zero", "palafin zero"],
    "tatsugiri-curly" : ["tatsugiri", "tatsugiri-curly", "tatsugiri curly"],
    "dudunsparce-two-segment" : ["dudunsparce", "dudunsparce-two-segment", "dudunsparce two segment", "dudunsparce-three-segment", "dudunsparce three segment"],
    "great-tusk" : ["great-tusk", "great tusk"],
    "scream-tail" : ["scream-tail", "scream tail"],
    "brute-bonnet" : ["brute-bonnet", "brute bonnet"],
    "flutter-mane" : ["flutter mane", "flutter-mane"],
    "slither-wing" : ["slither wing", "slither-wing"],
    "sandy-shocks" : ["sandy shocks", "sandy-shocks"],
    "iron-treads" : ["iron treads", "iron-treads"],
    "iron-bundle" : ["iron bundle", "iron-bundle"],
    "iron-hands" : ["iron hands", "iron-hands"],
    "iron-jugulis" : ["iron jugulis", "iron-jugulis"],
    "iron-moth" : ["iron moth", "iron-moth"],
    "iron-thorns" : ["iron thorns", "iron-thorns"],
    "wo-chien" : ["wo-chien", "wo chien"],
    "chien-pao" : ["chien-pao", "chien pao"],
    "ting-lu" : ["ting-lu", "ting lu"],
    "chi-yu" : ["chi-yu", "chi yu"],
    "roaring-moon" : ["roaring moon", "roaring-moon"],
    "iron-valiant" : ["iron valiant", "iron-valiant"],
    "koraidon-apex-build" : ["koraidon", "koraidon apex build", "koraidon-apex-build"],
    "miraidon-ultimate-mode" : ["miraidon", "miraidon ultimate mode", "miraidon-ultimate-mode"],
    "walking-wake" : ["walking wake", "walking-wake"],
    "iron-leaves" : ["iron leaves", "iron-leaves"],
    "poltchageist-counterfeit" : ["poltchageist", "poltchageist counterfeit", "poltchageist-counterfeit"],
    "sinistcha-unremarkable" : ["sinistcha", "sinistcha unremarkable", "sinistcha-unremarkable"],
    "gouging-fire" : ["gouging fire", "gouging-fire"],
    "raging-bolt" : ["raging bolt", "raging-bolt"],
    "iron-boulder" : ["iron boulder", "iron-boulder"],
    "iron-crown" : ["iron crown", "iron-crown"],
    "darmanitan-standard" : ["darmanitan", "darmanitan-standard", "darmanitan standard"]
}

export const options = {
    "abilities" : false,
    //"base_experience" : false,
    //"cries" : false,
    //"forms" : false,
    "game_indices" : false,
    "height" : false,
    //"held_items" : false,
    "id" : true,
    //"is_default" : false,
    //"location_area_encounters" : false,
    "moves" : false,
    //"order" : false,
    //"past_abilities" : false,
    //"past_types" : false,
    // "species" : false, //Why is this the same thing as name???
    "name" : true,
    //"sprites" : false,
    "stats" : false,
    "types" : false,
    "weight" : false,
};

export const gens = {
    "Gen 1" : true,
    "Gen 2" : false,
    "Gen 3" : false,
    "Gen 4" : false,
    "Gen 5" : false,
    "Gen 6" : false,
    "Gen 7" : false,
    "Gen 8" : false,
    "Gen 9" : false,
};

export const gensNums = {
    "Gen 1" : 151,
    "Gen 2" : 251,
    "Gen 3" : 386,
    "Gen 4" : 493,
    "Gen 5" : 649,
    "Gen 6" : 721,
    "Gen 7" : 809,
    "Gen 8" : 905,
    "Gen 9" : 1025
};

export async function get_batch_pokemon_data(){
    const batch_api_url = "https://pokeapi.co/api/v2/pokemon?limit=-1"
    const response = await fetch(batch_api_url);
    let data = await response.json();
    data = data["results"]
    let return_data = []

    for (const val of Object.keys(gens)){
        if(gens[val]){
            const lower_bound = val === "Gen 1" ? 0 : gensNums[val.substring(0, 4) + (parseInt(val.substring(4))-1).toString()]
            const upper_bound = gensNums[val] - 1

            let gen_data = []
            for(let i = lower_bound ; i <= upper_bound ; i++){
                gen_data.push([i+1, data[i]["name"]])
            }

            return_data.push([parseInt(val.substring(4)) ,gen_data])
        }
    }

    return return_data
}

export async function getPokemonData(pokeName, chosenOptions){
    const response = await fetch(api_url + pokeName);
    const data = await response.json();
    let returnData = {}

    for (option in chosenOptions){
        returnData[option] = data[option]
    }

    return returnData
}

export async function getRandomPokemonData(){
    const chosenOptions = Object.keys(options).filter((key) => options[key])
    const gensPicked = Object.keys(gens).filter((key) => gens[key])
    
    const gen = Math.floor(Math.random() * gensPicked.length);
    const min = (gensPicked[gen] == "Gen 1") ? 1 : (gensNums[gensPicked[gen].substring(0, 4) + (parseInt(gensPicked[gen].substring(4)) - 1).toString()] + 1);
    const index = Math.floor(Math.random() * (gensNums[gensPicked[gen]] - min + 1)) + min;
    
    const response = await fetch(api_url + index.toString());
    const data = await response.json();

    currentPokemon = data["name"]
    currentPokemonID = data["id"].toString()

    let returnData = {}

    for (const option of chosenOptions){
        returnData[option] = data[option]
    }

    return returnData
}

export function ParseHint(hint, data){ //Take out cries, forms, is_default

    if (hint == "abilities"){
        return data.map((ability) => ability["ability"]["name"])
    }

    else if (hint == "game_indices"){ //This may be wrong
        return data.map((game) => game["version"]["name"])
    }

    else if (hint == "moves"){
        return data.map((move) => move["move"]["name"])
    }

    else if (hint == "held_items"){
        return data.map((item) => item["item"]["name"])
    }

    else if (hint == "species"){
        return data["name"]
    }

    else if (hint == "stats"){
        return data.map((stat) => stat["stat"]["name"].toString() + " = " + stat["base_stat"].toString())
    }

    else if (hint == "types"){
        return data.map((type) => type["slot"].toString() + " = " + type["type"]["name"].toString())
    }

    else if (hint == "past_abilities"){
        return data[0]["abilities"].map((ability) => ability["ability"]["name"].toString())
    }

    return data
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function LoadDefaults(){

}

export function SaveDefaults(){
    // const fs = require('fs')

    // for (const option of Object.values(options)){
    //     fs.writeFile('defaults.txt', option.toString() + "/n", (err) => {
    //         if (err) throw err; //This is what happens when err is thrown
    //     })
    // }

    // for (const gen of Object.values(gens)){
    //     fs.writeFile('defaults.txt', gen.toString() + "/n", (err) => {
    //         if (err) throw err; //This is what happens when err is thrown
    //     })
    // }
}